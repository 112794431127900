import React from 'react';
import { Row, Col, Card, Carousel } from 'antd';
import './App.css';
import data from './data.json'

function isMobile() {
  return window.innerWidth <= 768;
}

class AntdCard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      expand: false
    }
  }

  render() {
    const item = this.props.item
    const status = this.props.status || {}
    const healthy = status.status_code && status.status_code >= 200 && status.status_code < 300
    const sick = status.status_code && status.status_code >= 400
    const access = status.count
    let cover = undefined
    const renderPic = (pic) => { return <div className="cover-img">
      <div className="cover-inner" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img-compressed/${pic})`}}></div>
    </div> }
    if (item.imgs.length === 1) cover = renderPic(item.imgs[0])
    else if (item.imgs.length > 1) cover = <Carousel autoplay={true} dotPosition="right">
      {item.imgs.map(renderPic)}
    </Carousel>
    return <Col ref={(e) => {this.el = e}} xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
      <div className="card-wrapper" style={{maxHeight: this.state.expand ? "none" : 480}}>
      <Card cover={cover && <div className="cover-container">{cover}</div>}>
        <Card.Meta title={<div className="card-meta-title-group">
          <div className="meta-header">
            {healthy && <div className="working-badge">&#9679;运行中</div>}
            {sick && <div className="stopped-badge">&#9632;已停止</div>}
            {!healthy && !sick && <div className="unknown-badge">&#11042;未知</div>}
            {access && access >= 0 && <div className="card-meta-access-count">访问次数:{access}</div>}
          </div>
          <div className="card-meta-title" onClick={() => {if (item.href && item.href.length > 0) window.open(item.href, '_blank')}}>{item.title}</div>
          <div className="card-meta-author">{item.author}</div>
        </div>} description={
          <div className="card-meta-description" onClick={() => this.setState({expand: !this.state.expand})}>{item.description}</div>
        }
        />
        {!this.state.expand && <div className="card-wrapper-bottom"></div>}
      </Card>
      </div>
    </Col>
  }

}

export default class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data: {},
      cnt: undefined
    }
  }

  componentDidMount() {
    fetch('https://isoa-2020-api.aminer.cn/all').then(resp => resp.json()).then(obj => {
      if (obj.status) this.setState({data: obj.data})
    })
    fetch('https://isoa-2020-api.aminer.cn/access').then(resp => resp.json()).then(obj => {
      if (obj.status) this.setState({cnt: obj.count})
    })
  }

  render() {
    const epdata = data.filter(d => d.category === 'epidemic')
    const academicdata = data.filter(d => d.category === 'academic')
    return (
      <div className="App">
        <div className="header">以智能服务为中心的软件开发设计与实现</div>
        <div className="main-body">
          <div className="column">
            <div className="content">
              <div className="bar" id="intro-title">简介</div>
              <div className="intro-section">
                {"\t“以智能服务为中心的软件开发设计与实现”是清华大学计算机系本科生的课程之一，由"}<a href="http://keg.cs.tsinghua.edu.cn/jietang/" target="_blank" rel="noopener noreferrer">唐杰老师</a>授课。
                在2020年春季的课程中，同学们分别以“COVID-19疫情可视化”和“学术信息挖掘”为主题，编写了一系列高可用的智能服务。
                以下为经同学们数周努力完成的各种有趣的功能。
              </div>
            </div>
          </div>
          {!isMobile() && <hr className="separator"/>}
          <div className="column">
            <div className="content">
              <div className="bar" id="epidemic-title">COVID-19疫情可视化专题</div>
              <Row type="flex">
                {epdata.map((item, idx) => <AntdCard key={idx} idx={idx} item={item} status={this.state.data[item.author]}/>)}
              </Row>
            </div>
          </div>
          {!isMobile() && <hr className="separator"/>}
          <div className="column">
            <div className="content">
              <div className="bar" id="academic-title">学术信息挖掘专题</div>
              <Row type="flex">
                {academicdata.map((item, idx) => <AntdCard key={idx} idx={idx} item={item} status={this.state.data[item.author]}/>)}
              </Row>
            </div>
          </div>
          <div className="nav">
            <div className="nav-btn" style={{background: "#cccccc"}}
              onClick={() => {
                const el = document.getElementById("intro-title")
                if (el) el.scrollIntoView()
              }}>简介</div>
            <div className="nav-btn" style={{background: "#c9ffbf"}}
              onClick={() => {
                const el = document.getElementById("epidemic-title")
                if (el) el.scrollIntoView()
              }}>{isMobile() ? "疫情" : "COVID-19疫情可视化专题"}</div>
            <div className="nav-btn" style={{background: "#c1ceff"}}
              onClick={() => {
                const el = document.getElementById("academic-title")
                if (el) el.scrollIntoView()
              }}>{isMobile() ? "学术" : "学术信息挖掘专题"}</div>
          </div>
          <div className="footnote">
            {this.state.cnt && <p>历史访问次数： {this.state.cnt}</p>}
            <p>iSOA 2020 Spring @THU</p>
          </div>
        </div>
      </div>
    )
  }
};
